@font-face {
  font-family: "Made Tommy Soft";
  src: url("../assets/MADE\ Tommy\ Soft\ Thin\ PERSONAL\ USE.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Made Tommy Light";
  src: url("../assets/MADE\ TOMMY\ Light_PERSONAL\ USE.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Black One";
  src: url("../assets/black-ops-one.regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.NavBar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  background-color: #0f1110;
}

.NavBar_Con {
  width: 90%;
  display: flex;
  align-items: center;
}

.NavBar_Grey {
  background-color: #202020;
}

.MenuCon {
  min-height: 50px;
  min-width: 120px;
  background-image: url("../assets/rhino\ btn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.MenuBgCon {
  position: absolute;
  right: 5%;
  z-index: 10;
  top: 80px;
  height: 250px;
  min-width: 250px;
  display: flex;
  align-items: center;
  background-image: url("../assets/menu.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.5s ease;
  overflow: hidden;
  visibility: hidden;
}

.MenuBgCon1 {
  position: absolute;
  right: 5%;
  z-index: 10;
  top: 80px;
  height: 300px;
  min-width: 300px;
  display: none;
  align-items: center;
  background-image: url("../assets/menu.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.5s ease;
  overflow: hidden;
  visibility: hidden;
}

.MenuShow {
  visibility: visible;
  opacity: 1;
  display: flex;
}

.MenuShow1 {
  visibility: visible;
  opacity: 1;
  display: none;
}

.HeroJukeCon {
  height: 18vh;
  width: 15vw;
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 25;
  bottom: 14vh;
  left: 18%;
}

.HeroJukeCon1 {
  min-height: 50px;
  min-width: 120px;
  background-image: url("../assets/rhino\ btn1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.MenuBgCon ul {
  overflow: hidden;
  list-style: none;
  margin: 0;
}

.MenuBgCon ul li {
  margin: 0;
  font-size: 20px;
  margin: 15px 10px;
  cursor: pointer;
  color: #000;
}

.MenuBgCon1 ul {
  overflow: hidden;
  list-style: none;
  margin: 0;
}

.MenuBgCon1 ul li {
  margin: 0;
  font-size: 15px;
  margin: 15px 10px;
  cursor: pointer;
  color: #000;
}

.Hero {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #0f1110;
  position: relative;
  overflow: hidden;
}

.Hero span {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.VideoPlayBtn {
  display: none;
  align-items: center;
  height: 50px;
  width: 155px;
  /* bottom: 20%;
    left: 20.5%; */
  bottom: 100px;
  right: 10px;
  border-radius: 25px;
  position: absolute;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  gap: 8px;
}

.VideoPlayIcon {
  margin-left: 10px;
}

.playing {
  background: #fff;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12.5px;
  margin-right: 2.5px;
  gap: 0 2px;
  box-sizing: border-box;
}

.playing__bar {
  display: inline-block;
  background: #000;
  max-width: 4px;
  max-height: 18px;
  animation: up-and-down 1.3s ease infinite alternate;
}

.playing__bar1 {
  height: 60%;
}

.playing__bar2 {
  height: 30%;
  animation-delay: -2.2s;
}

.playing__bar3 {
  height: 75%;
  animation-delay: -3.7s;
}

@keyframes up-and-down {
  10% {
    height: 30%;
  }

  30% {
    height: 100%;
  }

  60% {
    height: 50%;
  }

  80% {
    height: 75%;
  }

  100% {
    height: 60%;
  }
}

.Hero h1 {
  margin: 0;
  font-size: 7.43vw;
  font-weight: lighter;
  color: #fff;
  animation: textanimate 7s infinite step-start;
  filter: blur(1px);
  margin-top: 30px;
  font-family: "Made Tommy Soft";
  -webkit-text-stroke-width: 1px;
}

.HeroTitleBreak {
  display: none;
}

.Hero h2 {
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 5.2vw;
  margin-top: 10px;
  text-align: right;
}

.Hero h3 {
  margin: 0;
  display: none;
  color: #fff;
  font-weight: 500;
  font-size: 60px;
  text-align: left;
  margin-top: 20px;
}

.Logo {
  margin: 0;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
}

.MenuText {
  margin: 0;
  color: #fff;
  font-weight: 200;
  font-size: 20px;
  margin: 0 20px;
  cursor: pointer;
  font-family: "Made Tommy Light";
}

.HeroMobile {
  height: 100%;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
}

.HeroDesktop {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.HeroMobileBtnCon {
  display: none;
  align-items: center;
  justify-content: center;
}

.HeroButtonContainer {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.HeroWhitelistButton1 {
  height: 80px;
  width: 180px;
  background-color: #00f0ff;
  background: url("../assets/Union.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
}

.HeroWhitelistButton {
  height: 75px;
  width: 300px;
  background: url("../assets/Union.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 30px;
  border: none;
  cursor: pointer;
}

.HeroImage {
  position: absolute;
  width: 65vh;
  height: 55vh;
  right: 0px;
  bottom: 0px;
  object-fit: contain;
  /* background: url("../assets/ezgif.com-gif-maker\ \(2\).gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
}

.Hero video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: right;
}

.HeroLight1 {
  position: absolute;
  width: 50vh;
  height: 50vh;
  border-radius: 50%;
  right: 60px;
  bottom: 0px;
  animation: animate1 7s infinite step-start;
  mix-blend-mode: overlay;
  filter: blur(155px);
  /* transform: rotate(43.74deg); */
}

@keyframes animate1 {
  12.5% {
    background-color: #c8fbff;
  }
  25% {
    background-color: transparent;
  }
  37.5% {
    background-color: #d8ff00;
  }
  50% {
    background-color: transparent;
  }
  62.5% {
    background-color: #ff004f;
  }
  75% {
    background-color: transparent;
  }
  87.5% {
    background-color: #9e00ff;
  }
  100% {
    background-color: transparent;
  }
}

@keyframes textanimate {
  12.5% {
    color: #fff;
    text-shadow: 0px 0px 50px #00f0ff, 0px 0px 25px #00f0ff,
      0px 0px 10px #00f0ff;
    -webkit-text-stroke-color: #00f0ff;
  }
  25% {
    color: rgba(0, 240, 255, 0.5);
    text-shadow: 0px 0px 50px rgba(0, 240, 255, 0.5),
      0px 0px 25px rgba(0, 240, 255, 0.5), 0px 0px 10px rgba(0, 240, 255, 0.5);
    -webkit-text-stroke-color: rgba(0, 240, 255, 0.5);
  }
  37.5% {
    color: #ffffff;
    text-shadow: 0px 0px 50px #d8ff00, 0px 0px 25px #d8ff00,
      0px 0px 10px #d8ff00;
    -webkit-text-stroke-color: #d8ff00;
  }
  50% {
    color: rgba(216, 255, 0, 0.5);
    text-shadow: 0px 0px 50px rgba(216, 255, 0, 0.5),
      0px 0px 25px rgba(216, 255, 0, 0.5), 0px 0px 10px rgba(216, 255, 0, 0.5);
    -webkit-text-stroke-color: rgba(216, 255, 0, 0.5);
  }
  62.5% {
    color: #fec9d8;
    text-shadow: 0px 0px 50px #ff004f, 0px 0px 25px #ff004f,
      0px 0px 10px #ff004f;
    -webkit-text-stroke-color: #ff004f;
  }
  75% {
    color: rgba(255, 0, 79, 0.5);
    text-shadow: 0px 0px 50px rgba(255, 0, 79, 0.5),
      0px 0px 25px rgba(255, 0, 79, 0.5), 0px 0px 10px rgba(255, 0, 79, 0.5);
    -webkit-text-stroke-color: rgba(255, 0, 79, 0.5);
  }
  87.5% {
    color: #e6d1f3;
    text-shadow: 0px 0px 50px #9e00ff, 0px 0px 25px #9e00ff,
      0px 0px 10px #9e00ff;
    -webkit-text-stroke-color: #9e00ff;
  }
  100% {
    color: rgba(158, 0, 255, 0.5);
    text-shadow: 0px 0px 50px rgba(158, 0, 255, 0.5),
      0px 0px 25px rgba(158, 0, 255, 0.5), 0px 0px 10px rgba(158, 0, 255, 0.5);
    -webkit-text-stroke-color: rgba(158, 0, 255, 0.5);
  }
}

.FlexContainer {
  flex: 1;
}

.Social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  margin-top: 100px;
}

.SocialIconCon {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.SocialAddressCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: 50px;
  margin-top: 50px;
  border-radius: 30px;
  background-color: #000;
  border: 1.5px solid #d2d2d2;
  box-shadow: 0px 5px 0px #d2d2d2;
  margin-bottom: 50px;
}

.SocialAddressCon h4 {
  margin: 0;
  margin-left: 18px;
  font-weight: 400;
  color: #fff;
}

.SocialAddressCon button {
  height: 35px;
  width: 75px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 20px;
  background-color: #ff66c4;
  color: #000;
  margin-right: 10px;
  border: 1.5px solid #d2d2d2;
  box-shadow: 0px 3px 0px #d2d2d2;
  cursor: pointer;
  transition: 0.2s ease;
}

.SocialAddressCon button:hover {
  background-color: #fff;
}

.Intro {
  height: fit-content;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #000;
  background-image: url("../assets/brick.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.Intro span {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Intro h1 {
  color: #ff66c4;
  margin: 0;
  font-size: 45px;
  text-transform: uppercase;
  text-align: center;
  font-family: "Edo";
  font-weight: 300;
}

.Intro h2 {
  margin: 0 20px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-top: 10px;
}

.Intro h3 {
  font-size: 30px;
  font-weight: 500;
  margin: 0 20px;
  color: #fff;
  text-align: center;
  font-family: "ZingRust";
  margin-top: 60px;
}

.Intro p {
  margin: 0;
  margin-top: 20px;
  font-size: 40px;
  color: #fff;
  text-align: center;
  font-family: "ZingRust";
}

.Intro_Con {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}

.Intro_Card {
  width: 300px;
  height: 450px;
  /* background-image: url("../assets/aboutCard.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  border: 3px solid #fff;
  border-radius: 20px;
  background-color: transparent;
  box-shadow: 0px 0px 30px #ff009d inset, 0px 0px 30px #ff009d;
}

.Intro img {
  height: fit-content;
  width: 25%;
  object-fit: contain;
  border-radius: 50%;
  box-shadow: 0px 0px 30px #ff009d;
}

.Staking {
  /* min-height: 800px; */
  height: fit-content;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.Staking span {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Staking_Phase {
  display: flex;
}

.Staking_Phase2 {
  margin-left: 100px;
  margin-top: 20vh;
}

.Staking h1 {
  margin: 0;
  font-size: 8vw;
  font-weight: lighter;
  color: #fff;
  text-align: center;
  animation: textanimate 7s infinite step-start;
  filter: blur(1px);
  margin-top: 30px;
  font-family: "Made Tommy Soft";
  -webkit-text-stroke-width: 1px;
}

.Staking h2 {
  margin: 0;
  color: #ff66c4;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 35px;
  margin-top: 40px;
}

.Staking h3 {
  margin: 0;
  display: none;
  color: #fff;
  font-weight: 500;
  font-size: 60px;
  text-align: left;
  margin-top: 20px;
}

.Staking h4 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  margin-top: 40px;
}

.Staking ul {
  margin: 0;
  padding: 0;
  margin-top: 5px;
  margin-left: 15px;
}

.Staking li {
  color: #fff;
  margin-top: 10px;
}

.Staking video {
  width: 100%;
  /* position: absolute; */
  left: 0px;
  top: 0px;
}

.Powered {
  height: fit-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  /* padding-top: 100px; */
  background-color: #fdfdfd;
}

.Powered_Bottom{
  position: absolute;
  height: 5px;
  width: 100%;
  background-color: #000;
  bottom: 0px;
  z-index: 100;
}

.Powered video {
  width: 85%;
  margin-left: 7.5%;
}

.Powered h2 {
  margin: 0;
  color: #ff66c4;
  font-weight: 500;
  font-size: 35px;
  text-align: center;
  font-family: "Edo";
  font-weight: 300;
  margin-top: 100px;
}

.PoweredCon {
  display: flex;
  width: 90%;
  margin: 0 5%;
  z-index: 100;
  position: absolute;
}

.PoweredLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PoweredLeftCon {
  display: flex;
  margin-top: 70px;
}

.PoweredLeftCon img {
  width: 200px;
}

.PoweredLeftTextCon {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.PoweredLeftTop {
  display: flex;
  flex: 1;
  justify-content: space-evenly;
}

.PoweredLeftCon h3 {
  margin: 0;
  font-size: 25px;
}

.PoweredLeftCon p {
  margin: 0;
  margin-top: 10px;
  font-size: 18px;
}

.PoweredLeftCon h4 {
  margin: 0;
  font-size: 25px;
  margin-top: 70px;
  text-align: center;
  text-transform: uppercase;
}

.PoweredLeftCon1 {
  display: flex;
  flex-direction: column;
}

.PoweredRight {
  height: 550px;
  width: 300px;
  background-image: url("../assets/powered-banner.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.Privacy {
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.PrivacyCon {
  width: 90%;
}

.Slides {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.SlideCon1 {
  display: grid;
  padding: 40px 0;
  grid-template-columns: auto auto auto;
  width: 90%;
  justify-content: space-between;
}

.SlideCon2 {
  display: none;
  padding: 40px 0;
  width: 90%;
  justify-content: space-between;
}

.SlideCon3 {
  display: none;
  padding: 40px 0;
  width: 90%;
  justify-content: center;
}

.Slide {
  height: 300px;
  width: 410px;
  /* border: 1px #A5A5A5 solid; */
  margin: 0 10px;
  margin-top: 20px;
  /* background-image: url("../assets/tshirt1.png"); */
  /* background-position: center;
    background-repeat: no-repeat; */
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

.Slide img {
  height: 90%;
}

.Slide video {
  height: 100%;
  width: 100%;
}

.SliderCon {
  display: flex;
  width: 90%;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.SliderCon0 {
  display: none;
  width: 90%;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.SliderCon1 {
  display: none;
  width: 90%;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.TeamSliderCon {
  display: none;
  width: 90%;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.SliderLineCon {
  height: 5px;
  width: 80%;
  overflow: hidden;
  background-color: #a5a5a5;
}

.SliderLine {
  width: 30%;
  height: 100%;
  background-color: #292929;
  transition: 0.5s ease;
}

.Fassion {
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: #0f1110;
  /* background-image: url("../assets/fassion.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain; */
}

.Fassion h2 {
  margin: 0;
  position: absolute;
  top: 30px;
  font-weight: 500;
  z-index: 15;
  color: #fff;
}

.Fassion img {
  height: 100%;
  width: 70%;
  margin-top: -30px;
  object-fit: cover;
  z-index: 10;
}

.Fassion video {
  height: 100%;
  width: 100%;
  margin-top: -30px;
  object-fit: cover;
  z-index: 10;
}

.FassionCard {
  height: 300px;
  width: 90%;
  margin-top: 40px;
  /* background-color: #fff; */
  /* position: absolute;
    z-index: 20;
    top: 70px;
    right: 75px; */
  /* background-image: url("../assets/fassionCard.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FassionCard h1 {
  color: #ff66c4;
  margin: 0;
  font-size: 35px;
  text-transform: uppercase;
  text-align: center;
}

.FassionCard p {
  margin: 0;
  margin-top: 20px;
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.Power {
  height: fit-content;
  display: flex;
  background-color: #fcfcfc;
  align-items: center;
  position: relative;
  /* height: 100vh; */
  aspect-ratio: 1.79;
}

.Power span {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url("../assets/rewards.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  z-index: 150;
}

.PowerCard {
  width: 100%;
  height: fit-content;
  background-image: url("../assets/brick.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.PowerCard h1 {
  color: #ff66c4;
  margin: 0;
  width: 90%;
  font-size: 44px;
  text-transform: uppercase;
  text-align: left;
  margin-left: 5%;
  font-weight: 400;
  font-family: "Edo";
}

.PowerCard p {
  margin: 0;
  color: #fff;
  margin-top: 10px;
  font-size: 20px;
  text-align: left;
  width: 90%;
  margin-left: 5%;
  font-family: "ZingRust";
}

.PowerImgCon {
  height: fit-content;
  width: 50%;
  /* border: 1px #A5A5A5 solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  position: absolute;
  left: 0px;
  z-index: 100;
}

.PowerImgCon1 {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 50;
  background-image: url("../assets/Upper\ fill.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.PowerImgCon2 {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 50;
  background-image: url("../assets/Upper\ fill.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scaleY(-1);
}

.Power video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.About {
  height: fit-content;
  display: flex;
  align-items: center;
  position: relative;
  aspect-ratio: 1.777777;
  /* padding-top: 100px; */
}

.About span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  background-image: url("../assets/bspoke.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  z-index: 150;
}

.AboutCard {
  width: 100%;
  height: fit-content;
  background-image: url("../assets/brick.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  /* left: 0px; */
  display: none;
  /* position: absolute; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.AboutCard h1 {
  color: #ff66c4;
  margin: 0;
  width: 90%;
  font-size: 44px;
  text-transform: uppercase;
  text-align: left;
  margin-left: 5%;
  font-weight: 400;
  font-family: "Edo";
}

.AboutCard p {
  margin: 0;
  color: #fff;
  margin-top: 20px;
  font-size: 20px;
  text-align: left;
  width: 90%;
  margin-left: 5%;
  font-family: "ZingRust";
}

.AboutImgCon {
  height: 100%;
  width: 50%;
  /* border: 1px #A5A5A5 solid; */
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  position: absolute;
  right: 0px;
  z-index: 100;
}

.About video {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.FAQ {
  height: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  background-color: #202020;
}

.row-title-text {
  font-size: 20px;
}

.FAQ h1 {
  font-size: 44px;
  text-align: center;
  font-weight: 500;
  color: #ff66c4;
  margin: 0;
}

.FAQSep {
  width: 20px;
  height: 30px;
}

.FAQCon {
  width: 850px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FAQ h3 {
  color: #ccc;
  margin: 0;
  font-weight: 500;
  font-size: 25px;
}

.FAQComCon {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
}

.FAQCom {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.FAQQcon {
  height: 65px;
  min-width: 100%;
  border: #ccc 1px solid;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
}

.FAQCom p {
  color: #fff;
  width: 80%;
  font-size: 17px;
  margin: 0;
  margin-left: 30px;
}

.FAQCom h2 {
  margin: 0;
  color: #fff;
  margin-left: 30px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
}

.FAQAnsCon {
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: 0.5s ease;
}

.FAQOpen {
  height: auto;
}

.PlusCon {
  background-color: #fff;
  height: 55px;
  min-width: 55px;
  border-radius: 27.5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Utility {
  height: auto;
  background-color: #0f1110;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/brick.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.Utility h1 {
  color: #ff66c4;
  margin: 0;
  font-size: 44px;
  text-transform: uppercase;
  margin-top: 40px;
  font-family: "Edo";
  font-weight: 300;
}

.Utility span {
  display: grid;
  padding: 40px 0;
  grid-template-columns: auto auto auto;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 50px;
}

.UtilityLight {
  width: 110px;
  height: 110px;
  background: #3b4eff;
  opacity: 0.6;
  top: 20px;
  border-radius: 50%;
  filter: blur(50px);
  position: absolute;
}

.UtilityLight2 {
  width: 110px;
  height: 110px;
  background: #bc1c8a;
  opacity: 0.6;
  top: 30px;
  border-radius: 50%;
  filter: blur(50px);
  position: absolute;
}

.UtilityCard {
  height: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
}

.UtilityCard1 {
  height: auto;
  display: none;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
  width: 100%;
  flex-direction: row;
}

.Utility img {
  width: 180px;
  height: 180px;
  object-fit: contain;
}

.Utility p {
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.Rarity {
  height: auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}

.Rarity h1 {
  color: #ff66c4;
  margin: 0;
  font-size: 44px;
  font-weight: 500;
  text-transform: uppercase;
}

.Rarity h2 {
  color: #fff;
  margin: 0;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
}

.RarityText1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RarityText2 {
  display: none;
}

.Rarity ul {
  color: #fff;
  margin: 0;
  width: 100%;
  font-size: 16px;
  font-family: "Made Tommy Light";
  font-weight: 500;
  text-align: left;
  margin-top: 10px;
  list-style: none;
}

.Rarity li {
  margin-top: 5px;
}

.Rarity ul li::before {
  content: "\2022";
  color: #fff;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.Rarity span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 50px;
}

.Rarity img {
  height: 150px;
  margin-top: 50px;
}

.RarityTopCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RarityCard {
  width: 500px;
  height: 750px;
  background-image: url("../assets/rarityCard.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.RarityCardCon {
  display: flex;
  width: 75%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RarityLight {
  width: 110px;
  height: 110px;
  background: #3b4eff;
  opacity: 0.6;
  top: 80px;
  border-radius: 50%;
  filter: blur(50px);
  position: absolute;
}

.RarityLight2 {
  width: 110px;
  height: 110px;
  background: #bc1c8a;
  opacity: 0.6;
  top: 80px;
  border-radius: 50%;
  filter: blur(50px);
  position: absolute;
}

.Work {
  height: auto;
  background-color: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}

.Work h1 {
  color: #ff66c4;
  margin: 0;
  font-size: 44px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.Work p {
  color: #fff;
  text-align: center;
  font-size: 16px;
}

.Work img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.WorkCard {
  height: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 25px;
}

.WorkDiv {
  display: flex;
  justify-content: center;
}

.Work span {
  display: flex;
  flex-direction: column;
  border: #a5a5a5 1px solid;
  padding-top: 30px;
}

.ArrowDown {
  height: 50px;
  width: 50px;
  margin-bottom: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/arrowdown.png");
}

.ArrowLeft {
  height: 85px;
  width: 150px;
  margin-bottom: 25px;
  margin-top: 75px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/arrowleft.png");
}

.ArrowRight {
  height: 85px;
  width: 150px;
  margin-top: 75px;
  margin-bottom: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/arrowright.png");
}

.WorkCon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WorkSeperator {
  width: 300px;
}

.Team {
  height: auto;
  background-color: #f8f7f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-top: 50px; */
  aspect-ratio: 1.7777;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../assets/team.webp");
  margin-top: -11px;
  margin-left: -10px;
}

.Team h1 {
  color: #ff66c4;
  margin: 0;
  font-size: 44px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Edo";
  font-weight: 300;
}

.TeamCon {
  display: grid;
  grid-template-columns: calc(20% - 20px) calc(20% - 20px) calc(20% - 20px) calc(
      20% - 20px
    ) calc(20% - 20px);
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.TeamCon1 {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}

.TeamCon2 {
  display: none;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
}

.TeamCard {
  width: 100%;
  aspect-ratio: 0.614;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin-top: 40px;
  perspective: 1000px;
}

.TeamCard img {
  width: 70%;
}

.TeamCard h2 {
  margin: 0;
  width: 70%;
  color: #ff66c4;
  margin-top: 10px;
  text-align: left;
  text-transform: uppercase;
}

.TeamCard h3 {
  margin: 0;
  color: #fff;
  width: 70%;
  text-align: left;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 10px;
}

.TeamCard p {
  margin: 0;
  color: #fff;
  width: 70%;
  text-align: left;
  margin-top: 10px;
}

.flip-card {
  width: 300px;
  height: 555px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin-top: 50px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.TeamCard:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/rarityCard.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: black;
}

.flip-card-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/rarityCard2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: white;
  transform: rotateY(180deg);
}

.RoadMap {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: #fff;
}

.RoadMap h1 {
  color: #ff66c4;
  margin: 0;
  font-size: 44px;
  font-weight: 500;
  text-transform: uppercase;
}

.RoadMapImgCon {
  min-height: 1550px;
  width: 1072px;
  position: relative;
  background-image: url("../assets/road.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 50px;
}

.RoadMapConMob {
  height: auto;
  position: relative;
  display: none;
  width: 90%;
  margin-top: 50px;
}

.RoadMapLine {
  width: 50px;
  height: 1700px;
  background-color: #202020;
  border-radius: 35px;
}

.RoadMapCon {
  width: auto;
  height: auto;
  position: absolute;
  left: 22%;
  top: 3.1%;
}

.RoadMapCon1 {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 20%;
}

.RoadMapCon2 {
  width: auto;
  height: auto;
  position: absolute;
  left: 22%;
  top: 36.8%;
}

.RoadMapCon3 {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 53.7%;
}

.RoadMapCon4 {
  width: auto;
  height: auto;
  position: absolute;
  left: 22%;
  top: 70.6%;
}

.RoadMapCon5 {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 87.5%;
}

.RoadMap li {
  font-size: 18px;
  font-weight: 400;
}

.RoadMap ul {
  height: 178px;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.RoadMap ul li::before {
  content: "\2022";
  color: #ff66c4;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.RoadMapImg1 {
  height: 75px;
  width: 75px;
  object-fit: cover;
}

.RoadMapImg2 {
  height: 75px;
  width: 130px;
  object-fit: cover;
}

.Product {
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #0f1110;
  position: relative;
  overflow: hidden;
}

.Product_Span {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 5%;
}

.ProductCon {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.ProductCon h4 {
  color: #6e6e6e;
  margin: 0;
  font-size: 20px;
  margin-top: 20px;
  margin-left: 2.5%;
}

.ProductCon h5 {
  margin: 0;
  font-weight: lighter;
  font-size: 17px;
  margin-top: 10px;
  margin-left: 2.5%;
}

.ProductCon h1 {
  margin: 0;
  font-size: 30px;
  margin-left: 20px;
}

.ProductCon h2 {
  margin: 0;
  font-size: 20px;
  margin-top: 15px;
  font-weight: lighter;
  margin-left: 20px;
}

.ProductCon a {
  margin: 0;
  font-size: 20px;
  margin-top: 15px;
  font-weight: lighter;
  margin-left: 20px;
}

.ProductImgCon {
  display: flex;
  height: auto;
  margin-top: 20px;
}

.ProductImgConLeft {
  height: 600px;
  width: 50%;
  display: flex;
  padding-bottom: 40px;
}

.ProductImgConRight {
  width: calc(50% - 2px);
  display: flex;
  border-left: #000 2px solid;
  flex-direction: column;
  align-items: flex-start;
}

.ProductImg1 {
  width: 60%;
  object-fit: cover;
  background-color: #202020;
}

.ProductImgConRight input {
  border: none;
  outline: 1px #000 solid;
  height: 45px;
  width: 45px;
  font-size: 17px;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0 10px;
}

.ProductImgCon1 {
  display: flex;
  width: 40%;
  flex-direction: column;
}

.ProductImg2 {
  width: 100%;
  height: 50%;
  object-fit: cover;
  background-color: #202020;
}

.ProductRewardCon {
  height: 45px;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 20px;
  color: #fff;
  position: relative;
  margin-top: 15px;
  margin-left: 20px;
  background-image: linear-gradient(#f5b18b, #ff66c4);
}

.ProductRewardCon2 {
  height: 45px;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 20px;
  color: #fff;
  position: relative;
  margin-top: 15px;
  margin-left: 20px;
  background-image: linear-gradient(#f5b18b, #ff66c4);
}

.ProductSize {
  height: 30px;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 20px;
  color: #fff;
  position: relative;
  margin-top: 15px;
  margin-left: 20px;
  background-color: #ff66c4;
}

.ProductSize1:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 5px solid white;
  border-left: 5px solid transparent;
  width: 0;
}

.ProductRewardCon1:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 10px solid white;
  border-left: 10px solid transparent;
  width: 0;
}

.ProductColorImgCon {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.ProductSizeCon {
  display: flex;
  align-items: center;
  width: 490px;
  justify-content: space-between;
}

.ProductSizeCon1 {
  display: flex;
  align-items: center;
}

.ProductColorImg {
  height: 100px;
  width: 100px;
  margin-left: 20px;
  outline: #000 1px solid;
}

.ProductShipCon {
  margin-left: 20px;
  margin-top: 40px;
  color: #fff;
  padding: 5px 40px;
  font-size: 17px;
  background-color: #000;
}

.ProductShipImg {
  width: 400px;
  object-fit: contain;
  margin-top: 20px;
  margin-left: 20px;
  background-color: #00f0ff;
}

.Shop {
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #0f1110;
  position: relative;
  overflow: hidden;
}

.Shop_Span {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-left: 5%;
}

.Shop_Con {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.Shop_Rare_Img {
  height: 70px;
  width: 50px;
  margin-left: 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Shop h1 {
  margin: 0;
  /* margin-left: 2.5%; */
  /* margin-top: 70px; */
  /* margin-bottom: 30px; */
  font-size: 50px;
  color: #000;
  text-decoration: underline;
  text-decoration-color: #ff66c4;
}

.Shop h2 {
  margin: 0;
  font-size: 17px;
  text-align: center;
}

.Shop h3 {
  margin: 0;
  font-size: 17px;
  margin-top: 10px;
  text-align: center;
}

.Shop_Product_Con {
  display: grid;
  width: 95%;
  margin-left: 2.5%;
  justify-content: space-between;
  grid-template-columns: 23% 23% 23% 23%;
}

.Shop_Product {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  outline: #6e6e6e 1px solid;
}

.Shop_Product button {
  height: 35px;
  border: none;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 17px;
  padding: 0 15px;
  color: #fff;
  cursor: pointer;
  background-color: #ff66c4;
}

.Shop_Product img {
  width: 100%;
  cursor: pointer;
}

.Footer {
  height: auto;
  background-color: #000;
  display: flex;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
}

.Footer span {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FootCon2 {
  display: none;
  flex-direction: column;
  width: 90%;
  align-items: center;
}

.FootCon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SocialCon {
  display: flex;
  align-items: center;
  margin-top: 80px;
}

.SocialIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #ff66c4;
  margin: 0 10px;
  /* border: #ff66c4 1px solid; */
  cursor: pointer;
}

.SocialTextCon {
  border: 1.5px solid #ffacc4;
  padding: 8px;
  border-radius: 18px;
  box-shadow: 0px 0px 5px #ff66c4 inset, 0px 0px 5px #ff66c4;
  position: relative;
}

.SocialTextCon1 {
  height: 100%;
  width: 100%;
  border: 1.5px solid #e6faff;
  border-radius: 18px;
  box-shadow: 0px 0px 5px #21ffff inset, 0px 0px 5px #21ffff;
  position: absolute;
  top: 3px;
  left: -1px;
}

.SocialTextCon h2 {
  margin: 0;
  color: transparent;
  font-weight: 900;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke: 1px #ffa9de;
  text-shadow: 0px 0px 8px #ff66c4;
  margin-top: 5px;
}

.SocialTextCon h4 {
  margin: 0;
  width: 142px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #000;
  text-shadow: 0px 0px 8px #21ffff;
}

.FootImg1 {
  height: 300px;
  width: 180px;
  background-image: url("../assets/1gif\ \(1\).gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.FootImg2 {
  width: 100px;
}

.Footer ul {
  list-style: none;
}
.Footer ul li {
  font-size: 20px;
  color: #fff;
  margin: 10px 0;
  cursor: pointer;
}

.Footer p {
  color: #a4a0a0;
  margin: 0;
  margin-top: 30px;
}

.Tokenomics {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/tokenomics1.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.Tokenomics_Img {
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/tokenomics1.webp");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);
}

.Clock {
  width: 350px;
  color: white;
  display: flex;
  font-size: 25px;
  font-weight: 400;
  align-items: center;
  margin: 0;
}

.Clock p {
  color: #fff;
  margin: 0px 10px;
  font-family: "Black One";
  font-size: 25px;
}

.ClockCon {
  /*height: 60px;*/
  width: auto;
  border-radius: 5px;
  color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Black One";
  font-size: 50px;
  font-weight: 500;
  text-shadow: 0px 0px 5px #000;
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.Modal_Con {
  position: fixed;
  background: #0f1110;
  outline: 2px #000 solid;
  border: 2px #ff66c4 solid;
  border-radius: 10px;
  width: auto;
  padding: 30px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Modal select {
  border: none;
  font-size: 15px;
  height: 35px;
  padding: 0 5px;
  border-radius: 8px;
  color: #fff;
  outline: 1px #fff solid;
  background-color: #000;
}

.display-block {
  display: flex;
}

.display-none {
  display: none;
}

.Modal_Img_Con {
  display: flex;
}

.Modal_Img_Con1 {
  display: flex;
  flex-direction: column;
}

.Modal_Img1 {
  height: 240px;
  width: 250px;
  margin-right: 10px;
  outline: 2px #ff66c4 solid;
  object-fit: cover;
  background-color: #00f0ff;
}

.Modal_Img2 {
  width: 115px;
  outline: 2px #ff66c4 solid;
  background-color: #00f0ff;
}

.Modal h1 {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  color: #fff;
  margin-top: 10px;
}

.Modal h2 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  margin-top: 10px;
}

.Modal p {
  margin: 0;
  width: 375px;
  font-weight: 100;
  color: #fff;
  margin-top: 5px;
}

.Modal_Btn_Con {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.Modal button {
  border: none;
  font-size: 15px;
  height: 35px;
  padding: 0 15px;
  border-radius: 8px;
  margin-left: 20px;
  color: #fff;
  outline: 1px #fff solid;
  background-color: #ff66c4;
  text-transform: uppercase;
  cursor: pointer;
}

.FlexCon {
  flex: 1;
}
/* 
  @media only screen and (max-width: 2559px) {
    .Hero h1{
        font-size: 185px;
      }
  }

  @media only screen and (max-width: 2463px) {
    .Hero h1{
        font-size: 180px;
      }
  }

  @media only screen and (max-width: 2395px) {
    .Hero h1{
        font-size: 175px;
      }
  }

  @media only screen and (max-width: 2335px) {
    .Hero h1{
        font-size: 160px;
      }
  }
  
  
  @media only screen and (max-width: 2040px) {
      .Hero h1{
          font-size: 142px;
        }
    }
    
    @media only screen and (max-width: 1700px) {
        .Hero h1{
            font-size: 114px;
        }
        .Hero h2{
            font-size: 78px;
        }
    }
    
    @media only screen and (max-width: 1500px) {
        .Hero h1{
            font-size: 111px;
        }
        
        }

@media only screen and (max-width: 1445px) {
.Hero h1{
    font-size: 108px;
}
} */

@media only screen and (max-width: 1450px) {
  .FassionCard p {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1380px) {
  /* .Hero h1{
        font-size: 100px;
    } */
  .RarityCard {
    width: 400px;
    height: 725px;
  }
  .Rarity ul {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1300px) {
  /* .Hero h1{
        font-size: 94px;
    } */
  /* .Hero h2{
        font-size: 60px;
    } */
  .Slide {
    height: 300px;
    width: 350px;
  }
  .RarityCard {
    width: 450px;
    height: 650px;
  }
  .Rarity h1 {
    font-size: 35px;
  }
  .RoadMap h1 {
    font-size: 35px;
  }

  .Team h1 {
    font-size: 35px;
  }

  .FAQ h1 {
    font-size: 35px;
  }

  .Work h1 {
    font-size: 35px;
  }

  .Rarity h2 {
    font-size: 18px;
  }

  .Rarity ul {
    font-size: 14px;
  }
  .Work p {
    font-size: 16px;
  }
  .Rarity img {
    height: 130px;
    margin-top: 35px;
  }
  .RarityLight {
    top: 60px;
  }
  .RarityLight2 {
    top: 60px;
  }
  .TeamCard {
    width: 280px;
    height: 525px;
  }
  .TeamCard p {
    font-size: 15px;
  }
  .Team h1 {
    margin-top: 50px;
  }
}

/* @media only screen and (max-width: 1255px) {
    .Hero h1{
        font-size: 82px;
    }
} */

@media only screen and (max-width: 1350px) {
  .FassionCard p {
    font-size: 26px;
  }
}

@media only screen and (max-width: 1250px) {
  .FassionCard p {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1221px) {
  .FassionCard p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .Rarity ul {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .RarityCardCon {
    width: 80%;
  }
  /* .Hero h1{
        font-size: 76px;
    } */
  /* .Hero h2{
        line-height: 60px;
        font-size: 53px;
    } */
  .Hero h3 {
    font-size: 30px;
  }
  .Clock {
    width: 220px;
  }
  .ClockCon {
    font-size: 30px;
  }
  .HeroWhitelistButton {
    height: 90px;
    width: 250px;
  }
  .Slide {
    height: 300px;
    width: 280px;
  }
  .SliderLineCon {
    width: 70%;
  }
  .Slide img {
    height: 75%;
  }
  .AboutCard h1 {
    font-size: 30px;
  }
  .PowerCard h1 {
    font-size: 30px;
  }
  .RarityCard {
    width: 300px;
    height: 550px;
  }
  .RarityCard h1 {
    font-size: 25px;
  }
  .Rarity h2 {
    font-size: 14px;
  }
  .Rarity ul {
    font-size: 13px;
    margin-top: 10px;
  }
  .Rarity img {
    height: 120px;
    margin-top: 20px;
  }
  .RarityLight {
    top: 50px;
  }
  .RarityLight2 {
    top: 50px;
  }
  .TeamCard {
    width: 280px;
    height: 525px;
    background-image: url("../assets/rarityCard.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .TeamCard p {
    font-size: 15px;
  }
  .TeamCard img {
    width: 60%;
    margin-top: 25px;
  }
  .TeamCon1 {
    display: none;
  }
  .TeamCon {
    display: none;
  }
  .TeamCon2 {
    display: grid;
  }
  .TeamSliderCon {
    display: flex;
  }
  .RoadMapImgCon {
    min-height: 1200px;
    width: 900px;
  }
  .RoadMap ul {
    height: 127px;
  }
  .RoadMapCon {
    left: 22%;
    top: 1.5%;
  }

  .RoadMapCon1 {
    left: 50%;
    top: 18.9%;
  }

  .RoadMapCon2 {
    left: 22%;
    top: 36.2%;
  }

  .RoadMapCon3 {
    left: 50%;
    top: 53.5%;
  }

  .RoadMapCon4 {
    left: 22%;
    top: 70.9%;
  }

  .RoadMapCon5 {
    left: 50%;
    top: 88%;
  }

  .RoadMap li {
    font-size: 15px;
    line-height: 18px;
  }
  .RoadMapImg1 {
    height: 65px;
    width: 65px;
  }

  .RoadMapImg2 {
    height: 65px;
    width: 120px;
  }
  .Powered {
    height: 100vh;
  }
  .Powered video {
    position: absolute;
    bottom: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .HeroJukeCon{
    bottom: 14vh;
    left: 16%;
  }
}

@media only screen and (max-width: 1000px) {
  .TeamCard {
    width: 260px;
    height: 485px;
  }
  .TeamCard h3 {
    width: 80%;
  }
  .TeamCard h2 {
    width: 80%;
  }
  .TeamCard p {
    width: 80%;
  }
  .FassionCard {
    height: 250px;
  }
  .Hero h1 {
    text-align: center;
    /* font-size: 71px; */
  }
  /* .Hero h2{
    font-size: 45px;
    line-height: normal;
} */
  .RarityCard {
    width: 280px;
    height: 520px;
  }
  .Rarity ul {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1023px) {
  .HeroImage {
    position: absolute;
    width: 70vh;
    height: 70vh;
    right: 0px;
  }
  .HeroLight1 {
    width: 45vh;
    height: 45vh;
    right: 10px;
  }
}

/* @media only screen and (max-width: 950px) {
    .Hero h1{
        font-size: 68px;
    }
} */

/* @media only screen and (max-width: 905px) {
    .Hero h1{
        font-size: 65px;
    }
} */

@media only screen and (max-width: 976px) {
  .FassionCard h1 {
    font-size: 30px;
  }
  .HeroJukeCon{
    left: 12%;
  }
}

@media only screen and (max-width: 902px) {
  .FassionCard h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .HeroButtonContainer {
    flex-direction: column;
    align-items: flex-start;
  }
  .HeroWhitelistButton {
    margin-left: 0px;
  }
  .HeroImage {
    position: absolute;
    width: 60vh;
    height: 60vh;
    right: 0px;
  }
  .HeroLight1 {
    width: 35vh;
    height: 35vh;
    right: 10px;
  }
  .Intro_Con {
    flex-direction: column;
  }
  .Intro_Card {
    height: fit-content;
    width: 100%;
    padding-bottom: 60px;
  }
  .Intro img {
    width: 50%;
    margin: 50px 0;
  }
  .Intro h1 {
    font-size: 25px;
  }
  .Intro p {
    font-size: 20px;
  }
  .Social {
    margin-top: 60px;
  }
  /* .Hero h2{
        font-size: 40px;
    } */
  .SliderCon {
    display: none;
  }
  .SliderCon0 {
    display: flex;
  }
  .SlideCon1 {
    display: none;
  }
  .SlideCon2 {
    display: grid;
    padding: 40px 0;
    grid-template-columns: auto auto;
  }
  .SliderLineCon {
    width: 60%;
  }
  .HeroJukeCon{
    left: 8%;
  }
  /* .About{
        height: auto;
        padding: 50px 0;
    }
    .About span{
        flex-direction: column;
    }
    .AboutCard{
        width: 700px;
        height: 450px;
        position: relative;
        align-self: start;
    }
    .AboutImgCon{
        width: 80%;
        margin-top: 30px;
    } */
  /* .Power{
        height: auto;
        padding: 50px 0;
    }
    .Power span{
        flex-direction: column;
    }
    .PowerCard{
        width: 700px;
        height: 450px;
        position: relative;
        align-self: start;
    }
    .PowerImgCon{
        width: 80%;
        margin-top: 30px;
    } */
  .Utility span {
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .UtilityCard {
    display: none;
  }
  .UtilityCard1 {
    display: flex;
  }
  .Utility p {
    color: #fff;
    margin-left: 10px;
    text-align: left;
  }
  .WorkSeperator {
    width: 150px;
  }
  .ArrowRight {
    width: 120px;
  }
  .ArrowLeft {
    width: 120px;
  }
  .Rarity span {
    flex-direction: column;
  }
  .RarityLight {
    top: 40%;
    left: 3%;
  }
  .RarityCardCon {
    width: 65%;
    margin-left: 5%;
  }
  .Rarity {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .RarityLight2 {
    top: 40%;
    left: 3%;
  }
  .RarityCard {
    flex-direction: row;
    margin-bottom: 50px;
    width: 750px;
    height: 400px;
    background-image: url("../assets/rarityCard1.png");
  }
  .Rarity h2 {
    text-align: left;
  }
  .RarityText1 {
    display: none;
  }
  .RarityText2 {
    display: flex;
  }
  .RarityTopCard {
    margin-left: 20px;
  }
  .Clock p {
    margin: 0 15px;
  }
  .Rarity ul {
    font-size: 13px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 850px) {
  .TeamCon2 {
    display: flex;
    overflow-x: scroll;
    align-items: center;
    justify-content: start;
    scrollbar-color: transparent;
  }
  .TeamCard {
    min-width: 260px;
    min-height: 485px;
    margin-right: 30px;
  }
  .Logo {
    font-size: 45px;
  }
  .MenuCon {
    width: 100px;
  }
  .TeamSliderCon {
    display: none;
  }
}

@media only screen and (max-width: 866px) {
  .HeroTitleBreak {
    display: flex;
  }
  .Hero h1 {
    font-size: 90px;
    align-self: center;
  }
  .Staking h1 {
    font-size: 11vw;
  }
  .FAQCon {
    width: 750px;
  }
}

@media only screen and (max-width: 820px) {
  .FassionCard {
    height: 200px;
  }
  .Fassion img {
    margin-top: -20px;
  }
  .FassionCard h1 {
    font-size: 28px;
  }
  .FassionCard p {
    font-size: 18px;
  }
  .HeroJukeCon{
    left: 3%;
  }
}

@media only screen and (max-width: 800px) {
  .Hero h1 {
    font-size: 80px;
  }
  .HeroImage {
    position: absolute;
    width: 40vw;
    height: 40vw;
    right: 0px;
  }
  .MenuText {
    display: none;
  }
  .MenuShow1 {
    display: flex;
  }
  .MenuShow {
    display: none;
  }
  .RoadMapImgCon {
    display: none;
  }
  .RoadMapConMob {
    display: flex;
  }
  .RoadMapImg1 {
    height: 50px;
    width: 50px;
  }
  .RoadMapImg2 {
    height: 50px;
    width: 120px;
  }
  .RoadMapCon {
    left: 0;
    top: 2%;
  }
  .RoadMapCon1 {
    left: 0;
    top: 15%;
  }

  .RoadMapCon2 {
    left: 0;
    top: 30%;
  }

  .RoadMapCon3 {
    left: 0;
    top: 48%;
  }

  .RoadMapCon4 {
    left: 0;
    top: 63%;
  }

  .RoadMapCon5 {
    left: 0;
    top: 80%;
  }
  .RoadMap ul {
    height: auto;
  }
  .RoadMap li {
    font-size: 17px;
    line-height: 30px;
    margin-left: 42px;
  }
  .Shop_Rare_Img {
    height: 45px;
    width: 30px;
    margin-left: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .Shop h1 {
    margin: 0;
    font-size: 30px;
  }
  .Shop_Product_Con {
    grid-template-columns: 31% 31% 31%;
  }
  .ProductImgCon {
    flex-direction: column;
  }
  .ProductImgConLeft {
    width: 90%;
    margin-left: 5%;
  }
  .ProductImgConRight {
    width: 90%;
    margin-left: 5%;
    border: none;
  }
  .ProductShipImg {
    width: 90%;
  }

  .MenuBgCon {
    display: none;
  }

  .MenuBgCon1 {
    display: flex;
  }
  .VideoPlayBtn{
    display: flex;
  }
  .HeroJukeCon{
    display: none;
  }
}

@media only screen and (max-width: 775px) {
  .FAQCon {
    width: 90%;
  }
  .FAQComCon {
    flex-direction: column;
  }
  .FAQCom {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .RarityCard {
    flex-direction: row;
    margin-bottom: 50px;
    width: 600px;
    height: 330px;
    background-image: url("../assets/rarityCard1.png");
  }
  .Hero h2 {
    font-size: 35px;
    line-height: normal;
  }
  .WorkSeperator {
    width: 100px;
  }
  .ArrowLeft {
    width: 80px;
  }
  .ArrowRight {
    width: 80px;
  }
  .Footer span {
    width: 90%;
  }
}

@media only screen and (max-width: 700px) {
  .WorkSeperator {
    width: 50px;
  }
  .ArrowLeft {
    width: 80px;
  }
  .ArrowRight {
    width: 80px;
  }
  .SlideCon2 {
    display: none;
  }
  .SlideCon3 {
    display: grid;
    grid-template-columns: auto;
  }
  .SliderCon {
    display: none;
  }
  .SliderCon0 {
    display: none;
  }
  .SliderCon1 {
    display: flex;
  }
  .Slide {
    min-height: 400px;
    min-width: 350px;
  }
}

@media only screen and (max-width: 660px) {
  .WorkSeperator {
    width: 80px;
  }
  .WorkCard {
    width: 200px;
  }
  .Shop_Product_Con {
    grid-template-columns: 48% 48%;
  }
}

@media only screen and (max-width: 600px) {
  .Hero {
    height: 70vh;
  }
  .Hero h1 {
    font-size: 70px;
  }
  .Hero video {
    height: 100%;
  }
  .VideoPlayBtn {
    position: absolute;
    bottom: 10px;
  }
  .Power {
    flex-direction: column-reverse;
  }
  .Power span {
    height: fit-content;
    background-image: none;
    aspect-ratio: none;
    background-color: rebeccapurple;
  }
  .PowerCard {
    display: flex;
    padding: 50px 0;
  }
  .PowerImgCon {
    position: relative;
    height: 50vh;
    width: 100%;
    margin: 0px;
  }
  .PowerImgCon1 {
    display: none;
  }
  .PowerImgCon2 {
    display: none;
  }
  .About {
    flex-direction: column-reverse;
    aspect-ratio: none;
  }
  .About span {
    background-image: none;
  }
  .AboutCard {
    display: flex;
    padding: 50px 0;
  }
  .AboutImgCon {
    position: relative;
    height: 50vh;
    width: 100%;
    margin: 30px 0px;
  }
  .RarityCard {
    width: 500px;
    height: 750px;
    background-image: url("../assets/rarityCard.png");
    flex-direction: column;
  }
  .Rarity h2 {
    text-align: center;
  }
  .RarityTopCard {
    margin-left: 0px;
  }
  .Rarity img {
    margin-left: 0px;
  }
  .RarityLight {
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .RarityLight2 {
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .RarityCardCon {
    width: 80%;
    margin-left: 0px;
  }
  .Rarity h2 {
    font-size: 16px;
    width: 80%;
  }
  .Rarity ul {
    width: 80%;
    margin-top: 40px;
    font-size: 16px;
  }
  .Rarity img {
    height: 150px;
    margin-top: 50px;
  }
  .WorkCard {
    width: 200px;
  }
  .Fassion img {
    height: 100%;
    width: 80%;
  }
  .Footer span {
    display: none;
  }
  .FootCon2 {
    display: flex;
    padding: 50px 0;
  }
  .FootCon {
    flex-direction: row;
    margin-top: 50px;
  }
  .SocialCon {
    margin-top: 50px;
  }
  .Powered {
    height: 600px;
  }
  .Powered video {
    width: 90%;
    margin-left: 5%;
  }
  .ProductImgConLeft {
    height: auto;
    flex-direction: column;
  }
  .ProductImg1 {
    width: 100%;
  }
  .ProductImgCon1 {
    width: 100%;
    flex-direction: row;
  }
  .ProductImg2 {
    height: auto;
    width: 50%;
  }
  .PoweredLeftCon {
    margin-top: 40px;
  }
  .PoweredLeftTop {
    flex-direction: column;
    gap: 30px 0;
  }
}

@media only screen and (max-width: 530px) {
  .Hero h1 {
    font-size: 60px;
  }
  .HeroWhitelistButton {
    display: none;
  }
  /* .HeroMobileBtnCon{
        display: flex;
    } */
}

@media only screen and (max-width: 500px) {
  .WorkSeperator {
    width: 50px;
  }
  .Hero h1 {
    font-size: 55px;
  }
  .Logo {
    font-size: 35px;
  }
  .Hero h2 {
    font-size: 30px;
    display: none;
  }
  .Hero h3 {
    display: flex;
    align-self: center;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
  }
  .Powered h2 {
    font-size: 25px;
    margin-top: 50px;
  }
  .MenuCon {
    min-width: 80px;
  }
  /* .HeroImage {
    position: absolute;
    width: 100vw;
    height: 58vh;
    bottom: 0;
  } */
  .Clock {
    width: auto;
  }
  .HeroButtonContainer {
    position: absolute;
    z-index: 20;
    bottom: -30px;
    align-self: center;
    align-items: center;
  }
  .HeroWhitelistButton {
    height: 80px;
    width: 200px;
  }
  .HeroLight1 {
    width: 30vh;
    height: 30vh;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  /* .HeroMobile{
        display: flex;
    } */
  /* .HeroDesktop{
        display: none;
    } */
  .Intro img {
    width: 70%;
  }
  .SliderCon {
    display: none;
  }
  .SliderCon0 img {
    width: 18%;
  }
  .SliderCon1 img {
    width: 18%;
  }
  .TeamSliderCon img {
    width: 18%;
  }
  .FassionCard h1 {
    font-size: 20px;
  }

  .FassionCard p {
    width: 90%;
    font-size: 13px;
  }
  /* .About{
        padding: 20px 0;
    }
    .AboutImgCon{
        height: 280px;
        margin-top: 10px;
    }
    .About img{
        width: 90%;
        object-fit: contain;
    } */
  .AboutCard h1 {
    font-size: 20px;
  }
  .AboutCard p {
    font-size: 14px;
  }
  /* .Power{
        padding: 20px 0;
    }
    .PowerImgCon{
        max-height: 280px;
        margin-top: 10px;
    }
    .Power img{
        width: 90%;
        object-fit: contain;
    }
    .PowerCard{
        width: 400px;
        height: 310px;
    }
    .PowerCard h1{
        font-size: 20px;
    }
    .PowerCard p{
        font-size: 14px;
    } */
  .Utility img {
    width: 100px;
    height: 100px;
  }
  .RoadMap li {
    font-size: 15px;
  }
  .WorkCard {
    max-width: 150px;
  }
  .Clock p {
    margin: 0 5px;
  }
  .Modal_Img1 {
    width: 200px;
  }
  .Modal p {
    width: 325px;
  }
  .ProductImgConLeft {
    width: 95%;
    margin-left: 2.5%;
  }
  .ProductImgConRight {
    width: 95%;
    margin-left: 2.5%;
    border: none;
  }
  .SocialAddressCon {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .WorkSeperator {
    width: 30px;
  }
  .RarityCard {
    width: 300px;
    height: 550px;
  }
  .Rarity h2 {
    font-size: 14px;
    width: 90%;
  }

  .Rarity ul {
    width: 90%;
    font-size: 13px;
    margin-top: 10px;
  }
  .Work p {
    font-size: 13px;
  }
  .Rarity img {
    margin-top: 20px;
    height: 110px;
  }
  .RarityLight {
    top: 15px;
  }
  .RarityLight2 {
    top: 15px;
  }
  .ClockCon {
    font-size: 25px;
  }
  .RoadMap li {
    line-height: 25px;
  }
  .RarityCardCon {
    width: 90%;
    margin-left: 0px;
  }
  .AboutCard h1 {
    font-size: 19px;
  }
  .AboutImgCon {
    height: 300px;
  }
  .PowerImgCon {
    max-height: 250px;
  }
  .PowerCard h1 {
    font-size: 19px;
  }
  .PowerCard p {
    margin-top: 5px;
  }
  .Hero h1 {
    font-size: 50px;
  }
  .Modal_Img1 {
    width: 150px;
  }
  .Modal p {
    width: 275px;
  }
  .Modal button {
    font-size: 13px;
  }
  .ProductRewardCon {
    padding: 15px 20px;
  }
  .ProductRewardCon2 {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 350px) {
  .Hero h1 {
    font-size: 40px;
  }
  .WorkCard {
    width: 140px;
  }
  .WorkSeperator {
    width: 10px;
  }
  .RoadMap li {
    line-height: 20px;
  }
  .AboutCard h1 {
    font-size: 18px;
  }
  .AboutCard p {
    font-size: 13px;
    margin-top: 5px;
  }
  .AboutImgCon {
    height: 260px;
  }
  .PowerCard h1 {
    font-size: 18px;
  }
  .PowerCard p {
    font-size: 13px;
    margin-top: 5px;
  }
  .PowerImgCon {
    max-height: 210px;
  }
}
