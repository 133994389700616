@font-face {
  font-family: 'Made Tommy';
  src: url('../src/assets/MADE\ TOMMY\ Regular_PERSONAL\ USE.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Edo';
  src: url('../src/assets/edo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ZingRust';
  src: url('../src/assets/ZingRustG2-BaseSh1.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App {
  overflow-x: hidden;
  position: relative;
  user-select: none;
  font-family: 'Made Tommy';
}

.App button{
  font-family: 'Made Tommy';
}

.Loader{
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 50;
  top: 0px;
  left: 0px;
  background-color: #0f1110;
}

.LoadHide{
  display: none;
}

.Loader h1{
  margin: 0;
  font-size: 25px;
  margin-top: 15px;
    font-weight: lighter;
    color: #fff;
    font-family: 'Made Tommy Soft';
    -webkit-text-stroke-width: 1px;
}


@keyframes textanimated {
  12.5%   {color: #fff;
      text-shadow: 0px 0px 5px #00F0FF, 0px 0px 25px #00F0FF, 0px 0px 10px #00F0FF;
      -webkit-text-stroke-color: #00F0FF;
  }
  25%  {color: rgba(0, 240, 255, 0.5);
      text-shadow: 0px 0px 5px rgba(0, 240, 255, 0.5), 0px 0px 25px rgba(0, 240, 255, 0.5), 0px 0px 10px rgba(0, 240, 255, 0.5);
      -webkit-text-stroke-color: rgba(0, 240, 255, 0.5);
      }
  37.5%  {color: #FFFFFF;
      text-shadow: 0px 0px 5px #D8FF00, 0px 0px 25px #D8FF00, 0px 0px 10px #D8FF00;
      -webkit-text-stroke-color: #D8FF00;}
  50%  {color: rgba(216, 255, 0, 0.5);
      text-shadow: 0px 0px 5px rgba(216, 255, 0, 0.5), 0px 0px 25px rgba(216, 255, 0, 0.5), 0px 0px 10px rgba(216, 255, 0, 0.5);
      -webkit-text-stroke-color:rgba(216, 255, 0, 0.5);}
  62.5%  {color: #fec9d8;
      text-shadow: 0px 0px 60px #FF004F, 0px 0px 35px #FF004F, 0px 0px 20px #FF004F;
      -webkit-text-stroke-color: #FF004F;}
  75%  {color: rgba(255, 0, 79, 0.5);
      text-shadow: 0px 0px 60px rgba(255, 0, 79, 0.5), 0px 0px 35px rgba(255, 0, 79, 0.5), 0px 0px 20px rgba(255, 0, 79, 0.5);
      -webkit-text-stroke-color:rgba(255, 0, 79, 0.5);}
  87.5% {color: #E6D1F3;
      text-shadow: 0px 0px 60px #9E00FF, 0px 0px 35px #9E00FF, 0px 0px 20px #9E00FF;
      -webkit-text-stroke-color: #9E00FF;}
  100%{color: rgba(158, 0, 255, 0.5);
      text-shadow: 0px 0px 60px rgba(158, 0, 255, 0.5), 0px 0px 35px rgba(158, 0, 255, 0.5), 0px 0px 20px rgba(158, 0, 255, 0.5);
      -webkit-text-stroke-color:rgba(158, 0, 255, 0.5);}
}